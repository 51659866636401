import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import { 
  Segment,
  Container,
  Header,
  Table,
  Icon,
  Grid
} from "semantic-ui-react";

const VideoPage = (props: LayoutProps) =>
<div>
  {/* Master Head */}
  <Segment vertical inverted textAlign="center">
    <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
    />
      <Container text>
        <Icon name="configure" size="huge"></Icon>
        <Header inverted as="h2">This page is currently under construction...</Header>
      </Container>
    </Segment>
    <Container>
      <Header dividing as="h2" className="gallery">Goals</Header>
        <Grid centered divided textAlign="center" verticalAlign="middle" className="box">
          <Grid.Row>
            { ["video","play","video","play"].map((item,index) => (<Grid.Column key={index} width="2" textAlign="center">
              <Icon name={item} size="big"></Icon>
            </Grid.Column>)) }
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header dividing as="h2" className="gallery">Assists</Header>
        <Grid centered divided textAlign="center" verticalAlign="middle" className="box">
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header dividing as="h2" className="gallery">On The Ball</Header>
        <Grid centered divided textAlign="center" verticalAlign="middle" className="box">
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="play" size="big"></Icon>
            </Grid.Column>
            <Grid.Column width="2" textAlign="center">
              <Icon name="video" size="big"></Icon>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
</div>

export default withLayout(VideoPage);